import { Injectable } from '@angular/core';
import { FirebaseConsumerSubscriptionsService } from 'src/app/firebase/consumer-subscriptions/firebase-consumer-subscriptions.service';
import { consumerSubscription } from 'src/models/consumerSubscription';
import { AuthService } from 'src/app/firebase/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConsumerSubscriptionsService {

  constructor(
    private fbConsumerSubscriptions: FirebaseConsumerSubscriptionsService,
    private auth: AuthService
  ) { }

  query(): Promise<consumerSubscription[]> {
    return new Promise((resolve, reject) => {
      const userId = this.auth.currentid;
      if(userId){
        this.fbConsumerSubscriptions.query(userId).then((subs: consumerSubscription[]) => {
          resolve(subs);
        }).catch(err =>{
          reject(err);
        })
      } else {
        resolve([]);
      }
    })
  }
}
