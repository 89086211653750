import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Input() placeholder: string;
  @Input() submitText: string;
  @Output() inputChanged: EventEmitter<string> = new EventEmitter();
  @Output() submitted: EventEmitter<any> = new EventEmitter();


  searchTerm;

  constructor() { }

  ngOnInit(): void {
  }

  searchChanged(e): void {
    this.searchTerm = e.detail.value;
    this.inputChanged.emit(this.searchTerm);
  }

  handleSubmit(): void {
    this.submitted.emit(this.searchTerm);
    this.searchTerm = '';

  }

}
