import { Component, OnInit } from '@angular/core';
import { RecipeService } from '../firebase/recipe/recipe.service';
import { NEW_RECIPE } from 'src/models/recipe';
import { ActivatedRoute, Router } from '@angular/router';
import { GroceryListService } from '../services/grocery-list/grocery-list.service';
import { GroceryItem } from 'src/models/groceryItem';
import { RecipeIngredient } from 'src/models/recipeIngredient';
import { GROCERY_LOCATION } from 'src/models/groceryLocation';
import { NavigationService } from '../services/navigation/navigation.service';
import * as Sentry from "@sentry/angular";
import { Location } from '@angular/common';
import { User } from 'src/models/user';
import { FirebaseShortLinkService } from '../firebase/short-link/short-link.service';
import { ShortLink } from 'src/models/shortLink';
import { Scale } from "@scottwittrock/recipe";

@Component({
  selector: 'app-page-recipe',
  templateUrl: './page-recipe.component.html',
  styleUrls: ['./page-recipe.component.scss']
})
@Sentry.TraceClassDecorator()
export class PageRecipeComponent implements OnInit {

  recipe: NEW_RECIPE;
  scale: number = 1;
  dinners: number = 0;
  user: User;
  shortLink:ShortLink;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private recipeService: RecipeService,
    private groceryListService: GroceryListService,
    private navigationService: NavigationService, 
    private shortLinkService: FirebaseShortLinkService
  ) { }

  ngOnInit(): void {
    this.navigationService.hide();
    const id = this.route.snapshot.params.id;
    this.recipeService.get(id).then((recipe) => {
      this.recipe = recipe;
      this.dinners = this.route.snapshot.queryParams.dinners || recipe.yield;
      this.scale = this.dinners / this.recipe.yield;
      this.shortLinkService.find(recipe.creator_id).then((shortLink: ShortLink) => {
        this.shortLink = shortLink;
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  addDinner() {
    this.dinners = this.dinners + 1;
    this.scale = this.dinners / this.recipe.yield;
  }
  removeDinner() {
    if(this.dinners > 1){
      this.dinners = this.dinners - 1;
      this.scale = this.dinners / this.recipe.yield;
    }
  }

  startCooking(){
    this.router.navigate(['cook', this.recipe.id]);
  }

  addToShoppingList() {
    const shoppingItems: GroceryItem[] = this.recipe.ingredients.map((recipeItem: RecipeIngredient) => {
      let quantity = recipeItem.quantity || null;
      if(quantity){
        quantity = Scale(recipeItem.quantity, recipeItem.measure, this.scale).quantity;
      }
      return {
        id: null,
        created: new Date(),
        title: recipeItem.title,
        original: recipeItem.original,
        purchased: false,
        purchased_date: null,
        quantity,
        measure: recipeItem.measure || null,
        aisle: GROCERY_LOCATION.OTHER,
        recipes: [this.recipe.id],
        recipe_titles: [this.recipe.name],
        deleted: false,
        review_status: ['needs review']
      };
    });
    this.groceryListService.bulkAdd(shoppingItems);
    this.navigationService.show();
    this.router.navigate(['shop']);

  }

  goback(){
    localStorage.setItem('no_user_creator_link', this.recipe.creator_id);
    this.navigationService.show();
    this.navigationService.back(`/creator/${this.recipe.creator_id}`);
  }

}
