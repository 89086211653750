import { Component, OnInit } from '@angular/core';
import { AuthService } from '../firebase/auth/auth.service';
import { Router } from '@angular/router';
import { NavigationService } from '../services/navigation/navigation.service';
import * as Sentry from "@sentry/angular";
import { AngularFireFunctions } from '@angular/fire/functions';
@Component({
  selector: 'app-page-auth',
  templateUrl: './page-auth.component.html',
  styleUrls: ['./page-auth.component.scss']
})
@Sentry.TraceClassDecorator()
export class PageAuthComponent implements OnInit {

  authError = '';
  currentView = 'email';

  constructor(
    private auth: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private aff: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.navigationService.hide();
  }

  async emailSubmitted(event: any) {
    const email = event.detail.email
    try {
      const emailExists = await this.auth.checkEmail(email);
      if(emailExists) {
        this.currentView = 'password';
      } else {
        this.currentView = 'createPass';
      }
    } catch (err) {
      console.error(err);
    }
  }
  createUser(event: any) {
    const email = event.detail.email;
    const pass = event.detail.password;
    this.auth.createUser(email, pass).then((uid) => {
      this.login(email, pass);
    }).catch((err) => {
      this.authError = err;
    });
  }

  loginSubmitted(event: any) {
    const email = event.detail.email;
    const pass = event.detail.password;
    this.login(email, pass);
  }
  login(email: string, pass: string) {
    this.auth.login(email, pass).then(() => {
      this.navigationService.show();
      this.router.navigate(['/']);
    }).catch((err: string) => {
      this.authError = err;
    })
   
  };

  forgotPassword(event: any) {
    const email = event.detail.email;
    this.auth.sendPasswordResetEmail(email).then(() => {
    }).catch((err: any) => {
      console.error(err);
    })
  }

}
