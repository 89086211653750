<g-nav-app-header
[heading]="'Meal Details'"
[action]="{
  id: 'cancel', 
  src: 'assets/consumer/icon_delete.svg'
}"
(actionClicked)="goBack()"
>
</g-nav-app-header>

<g-img *ngIf="meal?.imageUrl" class="feature" [src]="meal?.imageUrl">

</g-img>

<div class="content">
  <div class="title">{{meal?.title}}</div>
  <div class="dinner-container" style="min-height: 66px;">
    <div>{{displayTime}}</div>
    <g-pill *ngIf="meal">{{meal?.type}}</g-pill>
  </div>
  <div class="dinner-container">
    Number of people? 
    <div class="counter-container"> 
      <div (click)="removeDinner()">
        <g-img [src]="'assets/consumer/icon_subtract.svg'" class="button"></g-img>
      </div>
      <div>
        {{meal?.diners}}
      </div>
      <div (click)="addDinner()">
        <g-img [src]="'assets/consumer/icon_add_filled.svg'" class="button"></g-img>
      </div>
    </div>
  </div>
  <g-button
    *ngIf="!confirmRemovalPrompt"
    (gClick)="promptRemove()"
  >Remove From Plan</g-button>
  <g-button
    *ngIf="confirmRemovalPrompt"
    (gClick)="confirmRemoval()"
    [loading]="removing"
    style="--g-primary: red"
  >Confirm Removal</g-button>
</div>
