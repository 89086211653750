import { Injectable } from '@angular/core';
import { consumerSubscription } from 'src/models/consumerSubscription';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseConsumerSubscriptionsService {

  constructor(
    private afs: AngularFirestore
  ) { }


  query(userid: string): Promise<consumerSubscription[]> {
    return new Promise((resolve, reject) => {
      this.afs.collection('users')
        .doc(userid)
        .collection('consumer_subscription')
        .get()
        .toPromise()
        .then((ev) => {
          const docs = ev.docs;
          const subs: consumerSubscription[] = docs.map((doc) => {
            const sub: consumerSubscription = {
              id: '',
              start: doc.data().start.toDate(),
              end: doc.data().end ? doc.data().end.toDate(): null,
              creator: doc.data().creator
            }
            return sub;
          });
          resolve(subs);
        })
    })
  }
}
