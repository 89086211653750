<g-nav-app-header
[heading]="'Add to List'"
[action]="{
  id: 'cancel', 
  src: 'assets/consumer/icon_delete.svg'
}"
(actionClicked)="goBack()"
>
</g-nav-app-header>

<app-search-input
  [placeholder]="'What are you looking for?'"
  [submitText]="'add'"
  (inputChanged)="searchChanged($event)"
  (submitted)="submit($event)"

>

</app-search-input>
