<g-nav-app-header 
  [heading]="'Profile'"
>
</g-nav-app-header>


<div style="margin-left: 16px; margin-right: 16px;">
    <g-input
      [label]="'First Name'"
      [value]="user?.firstName"
      (changed)="updateFirstName($event)"
    ></g-input>
    <g-input
      [label]="'Last Name'"
      [value]="user?.lastName"
      (changed)="updateLastName($event)"
    ></g-input>

    <g-button
    (gClick)="signOut()"
    >Sign out</g-button>

    <div
      *ngIf="user?.adminPermissions.dashboard_creator"
      class="link"
      (click)="goToCreator()"
    >Go To Creator</div>
</div>
