<g-nav-app-header 
  [heading]="'Plan'"
  (actionClicked)="toggleCal()"
  [calendar]="calendar"
>
  <g-calendar-seven-day
    *ngIf="!showCalendar"
    [activeDate]="activeDate"
    [startDate]='currentStart'
    (changeDay)="changeDay($event)"
  >
  </g-calendar-seven-day>
  <g-calendar-month
    *ngIf="showCalendar"
    [activeDate]="activeDate"
    (changeDay)="changeDayMonth($event)"
  >
  </g-calendar-month>
</g-nav-app-header>

<g-calendar-seven-day
  *ngIf="!showCalendar"
  [activeDate]="activeDate"
  [startDate]='currentStart'
  (changeDay)="changeDay($event)"
>
</g-calendar-seven-day>
<g-calendar-month
  *ngIf="showCalendar"
  [activeDate]="activeDate"
  (changeDay)="changeDayMonth($event)"
>
</g-calendar-month>

<h1>Breakfast</h1>
<span *ngIf="planDay?.breakfast.length > 0">
  <app-meal-list-item *ngFor="let meal of planDay?.breakfast" [meal]="meal" (click)="goToMeal(meal)"></app-meal-list-item>
</span>
<app-add-plan [currentDate]="currentDate" [type]="'breakfast'"></app-add-plan>
<h1>Lunch</h1>
<span *ngIf="planDay?.lunch.length > 0">
  <app-meal-list-item *ngFor="let meal of planDay?.lunch" [meal]="meal" (click)="goToMeal(meal)"></app-meal-list-item>
</span>

<app-add-plan [currentDate]="currentDate" [type]="'lunch'"></app-add-plan>
<h1>Dinner</h1>
<span *ngIf="planDay?.dinner.length > 0">
  <app-meal-list-item *ngFor="let meal of planDay?.dinner" [meal]="meal" (click)="goToMeal(meal)"></app-meal-list-item>
</span>

<app-add-plan [currentDate]="currentDate" [type]="'dinner'"></app-add-plan>
<h1>Snack</h1>
<span *ngIf="planDay?.snack.length > 0">
  <app-meal-list-item *ngFor="let meal of planDay?.snack" [meal]="meal" (click)="goToMeal(meal)"></app-meal-list-item>
</span>
<app-add-plan [currentDate]="currentDate" [type]="'snack'"></app-add-plan>

<div style="width: calc(100vw - 120px); height: 50vh;"></div>