import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageCookComponent } from './page-cook/page-cook.component';
import { PagePlanComponent } from './page-plan/page-plan.component';
import { PageShopComponent } from './page-shop/page-shop.component';
import { PageCreatorComponent } from './page-creator/page-creator.component';
import { PageRecipeComponent } from './page-recipe/page-recipe.component';
import { PageAuthComponent } from './page-auth/page-auth.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo, hasCustomClaim } from '@angular/fire/auth-guard';
import { PageSearchComponent } from './page-search/page-search.component';
import { PageAddRecipeToPlanComponent } from './page-add-recipe-to-plan/page-add-recipe-to-plan.component';
import { PageMealDetailComponent } from './page-meal-detail/page-meal-detail.component';
import { PageSearchRecipesComponent } from './page-search-recipes/page-search-recipes.component';
import { PageConnectComponent } from './page-connect/page-connect.component';
import { PageRecipesComponent } from './page-recipes/page-recipes.component';
import { PageAuthGetStartedComponent } from './page-auth-get-started/page-auth-get-started.component';
import { PageProfileComponent } from './page-profile/page-profile.component';
import { PageCookRecipeComponent } from './page-cook-recipe/page-cook-recipe.component';


const redirectUnauthorizedToLogin = (router: any) => redirectUnauthorizedTo(['/auth']);
const redirectUnauthorizedToGetStarted = (router: any) => redirectUnauthorizedTo(['/get-started']);
const redirectSignedIn = () => redirectLoggedInTo(['']);

const routes: Routes = [{
  path: '',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToLogin },
  component: PageRecipesComponent
}, {
  path: 'recipes',
  // canActivate: [AngularFireAuthGuard],
  // data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageRecipesComponent
}, 
// {
//   path: 'creator',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToGetStarted },
//   component: PageCreatorComponent
// },  
{
  path: 'creator/:id',
  // canActivate: [AngularFireAuthGuard],
  // data: { authGuardPipe: redirectUnauthorizedToLogin },
  component: PageCreatorComponent
}, {
  path: 'plan',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PagePlanComponent
}, {
  path: 'plan/add',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageAddRecipeToPlanComponent
}, {
  path: 'meal/:id',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageMealDetailComponent
}, {
  path: 'shop',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageShopComponent
}, {
  path: 'cook',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageCookComponent
},  {
  path: 'cook/:id',
  // canActivate: [AngularFireAuthGuard],
  // data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageCookRecipeComponent
}, {
  path: 'search-recipes',
  // canActivate: [AngularFireAuthGuard],
  // data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageSearchRecipesComponent
}, {
  path: 'search',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageSearchComponent
}, {
  path: 'profile',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectUnauthorizedToGetStarted },
  component: PageProfileComponent
}, {
  path: 'recipe/:id',
  // canActivate: [AngularFireAuthGuard],
  // data: { authGuardPipe: redirectUnauthorizedToLogin },
  component: PageRecipeComponent
}, {
  path: 'c/:id',
  component: PageConnectComponent
}, {
  path: 'auth',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectSignedIn },
  component: PageAuthComponent
}, {
  path: 'get-started',
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: redirectSignedIn },
  component: PageAuthGetStartedComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
