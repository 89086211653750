import { Injectable } from '@angular/core';
import { FirebaseRecipesService, FirebaseRecipeQueryOptions } from 'src/app/firebase/recipes/recipes.service';
import { DocumentSnapshot } from '@angular/fire/firestore/interfaces';
import { NEW_RECIPE } from 'src/models/recipe';
import { consumerSubscription } from 'src/models/consumerSubscription';
import { AuthService } from 'src/app/firebase/auth/auth.service';
import { ConsumerSubscriptionService } from '../consumer-subscription/consumer-subscription.service';
import { ConsumerSubscriptionsService } from '../consumer-subscriptions/consumer-subscriptions.service';

export interface RecipeQueryOptions {
  start?: DocumentSnapshot<NEW_RECIPE> | undefined;
  limit?: number | undefined;
  sort?: 'email' | undefined;
  ids?: string[] | undefined;
}


@Injectable({
  providedIn: 'root'
})
export class RecipesService {

  constructor(
    private fbRecipesService: FirebaseRecipesService,
    private authService: AuthService,
    private consumerSubscriptionsService: ConsumerSubscriptionsService,
  ) { }

  query(options: RecipeQueryOptions): Promise<NEW_RECIPE[]>{
    return new Promise((resolve, reject) => {
      this.consumerSubscriptionsService.query().then((subs: consumerSubscription[]) => {
        const ids = options.ids || [];
        ids.push(this.authService.currentid);
        subs.forEach((sub) => {
          ids.push(sub.creator);
        });
        const newOptions: FirebaseRecipeQueryOptions = {
          ...options, 
          ids
        }
        console.log(newOptions);
        this.fbRecipesService.query(newOptions).then((recipes: NEW_RECIPE[]) => {
          localStorage.setItem('recipes', JSON.stringify(recipes));
          resolve(recipes);
        });
      })
    })

  }
}
