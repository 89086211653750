import { Injectable } from '@angular/core';
import { DocumentSnapshot } from '@angular/fire/firestore/interfaces';
import { NEW_RECIPE } from 'src/models/recipe';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';

export interface FirebaseRecipeQueryOptions {
  start?: DocumentSnapshot<NEW_RECIPE> | undefined;
  limit?: number | undefined;
  sort?: 'email' | undefined;
  ids?: string[] | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseRecipesService {

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService
  ) { }

  query(options: FirebaseRecipeQueryOptions): Promise<NEW_RECIPE[]> {
    const local = {
      start: options.start ? options.start : null,
      limit: options.limit ? options.limit : 100,
      sort: options.sort ? options.sort : 'created',
      // TODO: [MEAL-188] This has a hard limit of 10 from firebase
      ids: options.ids ? options.ids : [this.authService.currentid]
    };
    return new Promise((resolve, reject) => {
      this.afs.collection('recipes', ref => ref
        .where('creator_id', 'in', local.ids)
        .limit(local.limit)
        .orderBy(local.sort)
        .startAt(local.start)
      ).get().toPromise().then((snapshots: any) => {
        const recipes: NEW_RECIPE[] = snapshots.docs.map((snapshot: any)=> {
          return new NEW_RECIPE(
            snapshot
          );
        });
        resolve(recipes);
      }).catch((err) => {
        console.log(err);
      });
    });
  }
}
