import { Component, OnInit, Input } from '@angular/core';
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'app-fact-callout',
  templateUrl: './fact-callout.component.html',
  styleUrls: ['./fact-callout.component.scss']
})
@Sentry.TraceClassDecorator()
export class FactCalloutComponent implements OnInit {

  @Input() number: number;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
