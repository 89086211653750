import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageShopComponent } from './page-shop/page-shop.component';
import { PageCookComponent } from './page-cook/page-cook.component';
import { PagePlanComponent } from './page-plan/page-plan.component';
import { PageCreatorComponent } from './page-creator/page-creator.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { RecipeListItemComponent } from './shared/recipe-list-item/recipe-list-item.component';
import { FirebaseModule } from './firebase/firebase.module';
import { PageRecipeComponent } from './page-recipe/page-recipe.component';
import { PageAuthComponent } from './page-auth/page-auth.component';
import { AuthService, authFactory } from './firebase/auth/auth.service';
import { HeaderComponent } from './shared/header/header.component';
import { AuthorTagComponent } from './shared/author-tag/author-tag.component';
import { FactCalloutComponent } from './shared/fact-callout/fact-callout.component';
import { RecipeIngredientComponent } from './shared/recipe-ingredient/recipe-ingredient.component';

import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { PageSearchComponent } from './page-search/page-search.component';
import { AddPlanComponent } from './page-plan/add-plan/add-plan.component';
import { PageAddRecipeToPlanComponent } from './page-add-recipe-to-plan/page-add-recipe-to-plan.component';
import { SearchInputComponent } from './shared/search-input/search-input.component';
import { MealListItemComponent } from './shared/meal-list-item/meal-list-item.component';
import { PageMealDetailComponent } from './page-meal-detail/page-meal-detail.component';
import { PageSearchRecipesComponent } from './page-search-recipes/page-search-recipes.component';
import { PageConnectComponent } from './page-connect/page-connect.component';
import { PageRecipesComponent } from './page-recipes/page-recipes.component';
import { PageAuthGetStartedComponent } from './page-auth-get-started/page-auth-get-started.component';
import { PageProfileComponent } from './page-profile/page-profile.component';
import { PageCookRecipeComponent } from './page-cook-recipe/page-cook-recipe.component';
import { CookIngredientsComponent } from './page-cook-recipe/cook-ingredients/cook-ingredients.component';
import { CookStepComponent } from './page-cook-recipe/cook-step/cook-step.component';
@NgModule({
  declarations: [
    AppComponent,
    PageShopComponent,
    PageCookComponent,
    PagePlanComponent,
    PageCreatorComponent,
    NavigationComponent,
    RecipeListItemComponent,
    PageRecipeComponent,
    PageAuthComponent,
    HeaderComponent,
    AuthorTagComponent,
    FactCalloutComponent,
    RecipeIngredientComponent,
    PageSearchComponent,
    AddPlanComponent,
    PageAddRecipeToPlanComponent,
    SearchInputComponent,
    MealListItemComponent,
    PageMealDetailComponent,
    PageSearchRecipesComponent,
    PageConnectComponent,
    PageRecipesComponent,
    PageAuthGetStartedComponent,
    PageProfileComponent,
    PageCookRecipeComponent,
    CookIngredientsComponent,
    CookStepComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FirebaseModule
  ],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: authFactory,
      deps: [AuthService],
      multi: true
    },{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
