import { Component, OnInit } from '@angular/core';
// import { RecipeQueryOptions } from '../firebase/recipes/recipes.service';
import { NEW_RECIPE } from 'src/models/recipe';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../services/navigation/navigation.service';
import * as Sentry from "@sentry/angular";
import { UserService } from '../firebase/user/user.service';
import { RecipesService } from '../services/recipes/recipes.service';
@Component({
  selector: 'app-page-creator',
  templateUrl: './page-creator.component.html',
  styleUrls: ['./page-creator.component.scss']
})
@Sentry.TraceClassDecorator()
export class PageCreatorComponent implements OnInit {

  recipes: NEW_RECIPE[] = [];

  constructor(
    private recipesService: RecipesService,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    const id = this.route.snapshot.params.id;
    this.navigationService.show();
    this.recipesService.query({
      ids: [id]
    }).then((recipes: NEW_RECIPE[]) => {
      this.recipes = recipes;
    });


  }

  navToRecipe(id: string): void {
    this.router.navigate([`recipe/${id}`])
  }

  goToSearch(){
    this.router.navigate([`search-recipes`])
  }
}
