import { Injectable } from '@angular/core';
import { FirebaseConsumerSubscriptionService } from 'src/app/firebase/consumer-subscription/firebase-consumer-subscription.service';
import { consumerSubscription } from 'src/models/consumerSubscription';
import { isRejected } from 'q';

@Injectable({
  providedIn: 'root'
})
export class ConsumerSubscriptionService {

  constructor(
    private fbConsumerSubscriptionService: FirebaseConsumerSubscriptionService
  ) { }

  get(userId): Promise<consumerSubscription[]> {
    return new Promise((resolve, reject) => {
      this.fbConsumerSubscriptionService.get(userId).then((subs) => {
        resolve(subs);
      }).catch((err) => {
        reject();
      })
    });
  }

  add(userId, creatorId): Promise<void> {
    return new Promise((resolve, reject) => {
      const newSubscription: consumerSubscription = {
        id: null,
        creator: creatorId,
        start: new Date(),
        end: null
      }
      this.fbConsumerSubscriptionService.add(userId, newSubscription).then(() => {
        resolve();
      }).catch((err) => {
        reject();
      })
    }); 
  }
}
