



<g-empty-state
  [src]="'assets/consumer/heart_chef.svg'"
  [headline]="'Get Started'"
  [body]="'Create an account to create meal plans and shopping lists from your favorite creator.'"
  [button]="'Create Account'"
  (buttonClick)="goToSignUp()"
>

</g-empty-state>