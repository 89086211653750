<g-nav-app-header  [heading]="recipe?.name" [back]="{
  id: 'search', 
  src: 'assets/consumer/icon_back.svg'
}" (backClicked)="goBack()">

</g-nav-app-header>
<div [ngSwitch]="currentStep" class="container">
  <app-cook-ingredients *ngSwitchCase="0" [ingredients]="recipe?.ingredients" [scale]="scale"></app-cook-ingredients>

  <span *ngFor="let section of recipe?.sections; let sectionIndex = index">
      <div *ngFor="let step of section.steps; let stepIndex = index">
        <div *ngSwitchCase="(sectionIndex + 1) * (stepIndex + 1)" >
          <h4 style="font-size: 24px;">Step {{(sectionIndex + 1) * (stepIndex + 1)}} of {{totalSteps}}</h4>
          <app-cook-step [step]="recipe?.sections[sectionIndex].steps[stepIndex]" [scale]="scale"></app-cook-step>
        </div>
    </div>
  </span>
  <div *ngSwitchCase="totalSteps + 1" > 
    <g-empty-state
      [src]="'assets/consumer/heart_chef.svg'"
      [headline]="'Enjoy your meal!'"
      [body]="''"
    >
    </g-empty-state>
  </div>
</div>


<div class="nav-container">
  <div class="nav-back" (click)="goPrevious()">
    <g-img [src]="'assets/consumer/icon_back.svg'">
    </g-img>
  </div>

  <div class="nav-next" (click)="goForward()">
    <g-img [src]="'assets/consumer/icon_forward.svg'">

    </g-img>
  </div>
</div>