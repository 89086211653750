import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';
import { Location } from '@angular/common';
import { Ingredient } from "@scottwittrock/recipe";
import { PARSED_INGREDIENT } from '@scottwittrock/recipe/lib/ingredients';
import { GroceryListService } from '../services/grocery-list/grocery-list.service';
import { GROCERY_LOCATION } from 'src/models/groceryLocation';
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss']
})
@Sentry.TraceClassDecorator()
export class PageSearchComponent implements OnInit {

  searchTerm;

  constructor(
    private navigation: NavigationService,
    private location: Location,
    private groceryListService: GroceryListService
  ) {
    this.navigation.hide();
  }

  ngOnInit(): void {
    // TODO: Put input into focus on load
  }

  searchChanged(e): void{
    this.searchTerm = e;
  }
  submit(searchTerm): void{
    const result: PARSED_INGREDIENT = Ingredient.parse(searchTerm);
    this.groceryListService.add({
      title: result.title,
      original: searchTerm,
      aisle: GROCERY_LOCATION.OTHER,
      recipe_titles: [],
      id: '',
      created: new Date(),
      purchased: false,
      purchased_date: null,
      quantity: result.quantity,
      measure: result.measure,
      recipes: [],
      deleted: false,
      review_status: ['needs review']
    });
    this.searchTerm = '';
    this.goBack();
  }

  goBack(): void{
    this.navigation.show();
    this.location.back();
  }

}
