import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-auth-get-started',
  templateUrl: './page-auth-get-started.component.html',
  styleUrls: ['./page-auth-get-started.component.scss']
})
export class PageAuthGetStartedComponent implements OnInit {

  constructor(
    private navigation: NavigationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navigation.show();
  }
  goToSignUp() {
    const userId = localStorage.getItem('no_user_creator_link');
    if (userId) {
      this.router.navigate(['c', userId])
    } else {
      this.router.navigate(['auth']);
    }
  }

}
