import { Component, OnInit, Input } from '@angular/core';
import { MealType } from 'src/models/meal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {

  @Input()type: MealType;
  @Input()currentDate: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  addItem(){
    this.router.navigate(['plan/add'], {queryParams: {type: this.type, date: this.currentDate}});
  }

}
