import { Injectable } from '@angular/core';
import { FirebaseMealService } from 'src/app/firebase/meal/firebase-meal.service';
import { Meal, MealType } from 'src/models/meal';
import { NEW_RECIPE } from 'src/models/recipe';

@Injectable({
  providedIn: 'root'
})
export class MealService {

  constructor(
    private fbMealService: FirebaseMealService
  ) { }

  add(recipe: NEW_RECIPE, mealType: MealType, dateString: string, diners: number): Promise<void>{
    let imageUrl = '';
    if (recipe.images[0]) {
      imageUrl = recipe.images[0].url;
    }
    const newMeal: Meal = {
      id: '',
      created: new Date(),
      recipeId: recipe.id,
      title: recipe.name,
      imageUrl,
      diners,
      type: mealType,
      day: dateString
    };

    return this.fbMealService.add(newMeal);
  }

  get(id: string): Promise<Meal> {
    return this.fbMealService.get(id);
  }

  update(meal: Meal): Promise<void> {
    return this.fbMealService.update(meal);
  }
  delete(id: string): Promise<void> {
    return this.fbMealService.delete(id);
  }
}
