<g-nav-app-header
[heading]="'Add ' + type"
[action]="{
  id: 'cancel', 
  src: 'assets/consumer/icon_delete.svg'
}"
(actionClicked)="goBack()"
>
</g-nav-app-header>

<app-search-input
  [placeholder]="'What are you craving?'"
  (inputChanged)="searchChanged($event)"
  (submitted)="submit($event)"
>
</app-search-input>

<div class="searching" *ngIf="searching">
  Searching {{allRecipes.length}} recipes...
</div>

<div class="searching" *ngIf="!searching && filteredRecipes.length > 0">
    {{filteredRecipes.length}} recipes
  </div>

<span *ngIf="filteredRecipes.length > 0" >
    <app-recipe-list-item 
      *ngFor="let recipe of filteredRecipes"
      [recipe]="recipe"
      (click)="addToPlan(recipe)"
    ></app-recipe-list-item>
  </span>
  
  <g-empty-state 
    *ngIf="filteredRecipes.length === 0"
    [headline]="'No matching recipes'"
    [body]="'Try searching for another recipe or for ingredients you have on hand.'"
    [button]="'Browse all'"
    [src]="'assets/consumer/empty_basket.svg'"
    (buttonClick)="handleButtonClick()"
  >
  </g-empty-state>
