import { Component, OnInit } from '@angular/core';
import { AuthService } from '../firebase/auth/auth.service';
import { UserService } from '../firebase/user/user.service';
import { User } from 'src/models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-profile',
  templateUrl: './page-profile.component.html',
  styleUrls: ['./page-profile.component.scss']
})
export class PageProfileComponent implements OnInit {

  user: User

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userService.currentUser().then((user: User) => {
      this.user = user;
    })
  }

  updateFirstName(e){
    this.user.firstName = e.detail.value;
    this.saveUser()
  }
  updateLastName(e){
    this.user.lastName = e.detail.value;
    this.saveUser()
  }
  debounce;
  saveUser(){
    if (this.debounce) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.userService.update(this.user).then(()=> {
          console.log("User Updated")
        });
      }, 500)
    } else {
      this.debounce = setTimeout(() => {
        this.userService.update(this.user).then(()=> {
          console.log("User Updated")
        });;
      }, 500);
    }

  }

  signOut(){
    this.authService.logout();
  }

  goToCreator(){
    window.location.href = 'https://create.mymealplanner.app';
  }
}
