<g-nav-app-header
[heading]="'Shop'"
[action]="{
  id: 'add', 
  src: 'assets/consumer/icon_add.svg'
}"
(actionClicked)="showAdd($event)"
>
</g-nav-app-header>

<g-grocery-list
  *ngIf="items.length > 0"
  [items]="items"
  [hideEdit]="true"
  (updateItem)="updateItem($event)"
  (editItem)="editItem($event)"
  (deleteItem)="deleteItem($event)"
  [editSrc]="'assets/consumer/icon_edit.svg'"
  [deleteSrc]="'assets/consumer/icon_delete.svg'"
  [checkSrc]="'assets/consumer/icon_check.svg'"
>

</g-grocery-list>


<g-empty-state 
*ngIf="items.length === 0"
[headline]="'Empty List'"
[body]="'You can add a single item by pressing the + or bulk add items via any recipe page.'"
[src]="'assets/consumer/empty_basket.svg'"
>
</g-empty-state>