import { Injectable } from '@angular/core';
import { consumerSubscription } from 'src/models/consumerSubscription';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseConsumerSubscriptionService {

  constructor(
    private afs: AngularFirestore
  ) { }

  add(userId: string, sub: consumerSubscription ): Promise<any> {
    const newSub: any = {...sub};
    delete newSub.id;
    return this.afs.collection('users')
      .doc(userId)
      .collection('consumer_subscription')
      .add(newSub);
  }

  get(userId: string): Promise<consumerSubscription[]> {
    return new Promise((resolve, reject) => {
      this.afs.collection('users')
        .doc(userId)
        .collection('consumer_subscription').valueChanges({idField: 'id'}).subscribe((docs: any) => {
          const subs = docs as consumerSubscription[];
          console.log(subs);
          resolve(subs);
        });
    });
  }
}
