<g-auth 
  style="z-index: 100; position: relative;"
  [currentView]="currentView"
  (emailSubmitted)="emailSubmitted($event)"
  (createUser)="createUser($event)"
  (loginSubmitted)="loginSubmitted($event)"
  (forgotPassword)="forgotPassword($event)"

  [errorMessage]="authError"
  [background]="'https://images.unsplash.com/photo-1490818387583-1baba5e638af?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2131&q=80'"
>
  <h2>Start cooking with</h2>
  <app-author-tag
    [src]="shortLink?.image_url"
    [title]="shortLink?.headline"
    [subtitle]="shortLink?.subtitle"
  >
  </app-author-tag>
  <p>Create an account to view recipes, make meal plans, and get cooking!</p>
</g-auth>