import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
// https://github.com/angular/angularfire/issues/2656
import 'firebase/auth';
export const authFactory = (authService: AuthService) => {
  return () => authService.laod()
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentid: string = '';

  constructor(
    private afa: AngularFireAuth,
    private router: Router,
    private firestore: AngularFirestore,
    private userService: UserService
  ) { 
    if (environment.useEmulators) {
      firestore.firestore.app.auth().useEmulator('http://localhost:9099');
    }
  }

  laod() {
    return new Promise((resolve, reject) => {
      this.afa.user.subscribe((user: any)=>{
        if(user) {
          console.log("new user");
          this.currentid = user.uid;
          Sentry.setUser({ id: user.uid, email: user.email });
          resolve(user.uid);
        } else {
          console.log('No user');
          Sentry.configureScope(scope => scope.setUser(null));
          this.currentid = '';
          resolve();
        }
      })
    })
  }

  checkEmail(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afa.fetchSignInMethodsForEmail(email).then((value: Array<string>) => {
        if (value.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch((err) => {
        reject(err);
      });
    })
  }

  createUser(email: string, pass: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afa.createUserWithEmailAndPassword(email, pass).then((authUser) => {
        this.userService.create(email, authUser.user.uid).then(() => {
          resolve(authUser.user.uid);
        });
      }).catch((err) => {
        switch (err.code) {
          case 'auth/weak-password':
            reject('Password must be at least 6 characters');
            break;
          case 'auth/email-already-in-use':
            reject('An account with this email already exists. Please login.');
            break;
          case 'auth/invalid-email':
            reject('Provide a valid email address');
            break;
          case 'auth/operation-not-allowed':
            reject('Account creation not allowed. Please contact support.');
            break;
          default:
            reject('Please try again. Please contact support.');
            break;
        }
      });
    })
  }

  login(email: string, pass: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afa.signInWithEmailAndPassword(email, pass).then((value) => {
        resolve(value.user.uid);
      }).catch((err) => {
        switch (err.code) {
          case 'auth/invalid-email':
            reject('Provide a valid email address');
            break;
          case 'auth/user-disabled':
            reject('Your user has been disabled. Please contact support');
            break;
          case 'auth/user-not-found':
            reject("We couldn't find an account with this email address");
            break;
          case 'auth/wrong-password':
            reject('Your password was incorrect. Please try again or reset your password.');
            break;
          default:
            reject('Please try again. Please contact support.');
            break;
        }
        reject('There was an issue signing in. Please try again.');
      })
    })
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afa.sendPasswordResetEmail(email).then(() => {
        resolve()
      }).catch((err) => {
        reject(err);
      })
    })

  }

  logout(): void{
    this.afa.signOut().then(() => {
      this.router.navigate(['auth'])
    })
  }

}
