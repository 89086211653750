import { version } from "./version";
import '@capacitor/core';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@scottwittrock/garage/loader';

console.log({version});

if (environment.production) {
  Sentry.init({
    dsn: "https://0098396e4cef49949a14351e9ead252b@o521850.ingest.sentry.io/5632444",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "https://preview.mymealpanner.app", "https://plan.mymealplanner.app"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    
    environment: "production",
    release: `my-meal-planner-app@${version}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
  defineCustomElements(window);
