import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';
import * as Sentry from "@sentry/angular";
import { DateTime } from 'luxon';
import { PlanDayService } from '../services/plan-day/plan-day.service';
import { PlanDay } from 'src/models/planDay';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Meal } from 'src/models/meal';
import { AuthService } from '../firebase/auth/auth.service';

@Component({
  selector: 'app-page-plan',
  templateUrl: './page-plan.component.html',
  styleUrls: ['./page-plan.component.scss']
})
@Sentry.TraceClassDecorator()
export class PagePlanComponent implements OnInit, OnDestroy {

  currentDate: string;
  active = 0;
  activeDate = new Date();
  calendar = {
    id: 'calendar',
    date: this.activeDate
  }
  showCalendar = false;
  planDay: PlanDay = {
    breakfast: [],
    lunch: [],
    dinner: [],
    snack: []
  };

  currentStart: Date = new Date();

  planSubscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private planDayService: PlanDayService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navigationService.show();
    this.currentDate = DateTime.local().toISODate();
    this.planDayService.setDate(this.currentDate);
    this.planSubscription = this.planDayService.watch().subscribe((day: PlanDay) => {
      this.planDay = day;
    });
  }
  ngOnDestroy(): void{
    this.planSubscription.unsubscribe();
  }

  changeDate(dateString: string): void {
    this.currentDate = dateString;
    this.planDayService.setDate(dateString);
  }
  goToMeal(meal: Meal){
    this.router.navigate(['meal', meal.id]);
  }


  changeDay(e) {
    let currentDay = e.detail.dateobj.toISOString();
    this.activeDate = e.detail.dateobj;
    const newDateString = DateTime.fromISO(currentDay).toISODate();
    this.calendar = {
      id: 'calendar',
      date: e.detail.dateobj
    }

    this.changeDate(newDateString);
  }

  toggleCal(){
    console.log("Toggle Cal")
    this.showCalendar = !this.showCalendar;
  }

  changeDayMonth(e){
    let date = e.detail;
    this.active = null;
    this.activeDate = date;
    this.calendar = {
      id: 'calendar',
      date
    }
    this.currentStart = date;
    this.showCalendar = !this.showCalendar;
    console.log(date);
    const newDateString = DateTime.fromMillis(date.getTime()).toISODate();
    console.log(newDateString);
    this.changeDate(newDateString);
  }
}
