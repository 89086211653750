import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GroceryListService } from '../services/grocery-list/grocery-list.service';
import { GroceryItem } from 'src/models/groceryItem';
import { returnGroceryLocation } from 'src/models/groceryLocation';
import { GroceryListUpdateEvent, GroceryItemInput } from '@scottwittrock/garage/dist/types/components/grocery-list/grocery-list';
import { NavigationService } from '../services/navigation/navigation.service';


import * as Sentry from "@sentry/angular";
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-page-shop',
  templateUrl: './page-shop.component.html',
  styleUrls: ['./page-shop.component.scss']
})

@Sentry.TraceClassDecorator()
export class PageShopComponent implements OnInit, OnDestroy {

  items: GroceryItemInput[] = [];

  fullItems: GroceryItem[] = [];

  list$: Subscription;

  constructor(
    private router: Router,
    private groceryListService: GroceryListService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    this.navigationService.show();
    this.fullItems = JSON.parse(localStorage.getItem('shopping_items'));
    this.list$ = this.groceryListService.query().subscribe((items: GroceryItem[]) => {
      localStorage.setItem('shopping_items', JSON.stringify(items));
      this.fullItems = [...items];
      this.items = this.mapItems(items);
      console.log(items);
    });
  }

  ngOnDestroy(): void{
    this.list$.unsubscribe();
  }

  mapItems(items: GroceryItem[]): GroceryItemInput[] {
    return [...items.map((item: GroceryItem, index: number) => {
      let subtitle = '';
      if (item.quantity) {
        subtitle = item.quantity + ' ';
      }
      if (item.measure) {
        subtitle = subtitle + item.measure;
      }
      console.log(item);
      return {
        index,
        title: item.title,
        group: item.aisle,
        quantity: item.quantity,
        subtitle,
        purchased: item.purchased
      };
    })];

  }
  updateItem(e): void {
    const detail: GroceryListUpdateEvent = e.detail;
    const updated: GroceryItemInput = {...detail.updated};
    const newItem = {
      ...this.fullItems[detail.index],
      purchased: updated.purchased
    };
    this.groceryListService.update(newItem);
  }

  deleteItem(e): void{
    const detail: GroceryListUpdateEvent = e.detail;
    this.groceryListService.delete(this.fullItems[detail.index].id);
  }
  editItem(e): void {
    console.log(e);
  }
  showAdd(e): void{
    console.log(e);
    this.router.navigate(['search']);
  }

}
