import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Meal, MealType } from 'src/models/meal';
import { PlanDay } from 'src/models/planDay';
import { FirebaseMealsService } from 'src/app/firebase/meals/firebase-meals.service';
import { DateTime } from 'luxon';


@Injectable({
  providedIn: 'root'
})
export class PlanDayService {

  currentSubscription: Subscription;

  private planDaySubject: BehaviorSubject<PlanDay> = new BehaviorSubject({
    breakfast: [],
    lunch: [],
    dinner: [],
    snack: []
  });

  constructor(
    private fbMealsService: FirebaseMealsService
  ) {

  }

  watch(): BehaviorSubject<PlanDay> {
    return this.planDaySubject;
  }

  setToToday(){
    const todayDateString = DateTime.local().toISODate();
    this.setDate(todayDateString);
  }

  setDate(dateString: string): void{
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
    this.currentSubscription = this.fbMealsService.query(dateString)
      .subscribe((meals: Meal[]) => {
      const newDay: PlanDay = {
        breakfast: [],
        lunch: [],
        dinner: [],
        snack: []
      };
      meals.forEach((meal: Meal) => {
        switch (meal.type){
          case MealType.BREAKFAST:
            newDay.breakfast.push(meal);
            break;
          case MealType.LUNCH:
            newDay.lunch.push(meal);
            break;
          case MealType.DINNER:
            newDay.dinner.push(meal);
            break;
          case MealType.SNACK:
            newDay.snack.push(meal);
            break;
          default:
            break;
        }
      });
      this.planDaySubject.next(newDay);
    });
  }
}
