/**
 * The meal is the representation of a recipe assigned to a day and a meal time
 */
export interface Meal { 
  /** Unique id for the meal. */
  id: string;
  created: Date;
  /** Reference id for the meal which has been added. */
  recipeId: string;
  /** Title of the recipe for easier querying when displaying as a list. */
  title: string;
  /** Thumbnail URL for the key recipe image. */
  imageUrl: string;
  /** Number of people who will be consuming the meal. */
  diners: number;
  /** If it's breakfast, lunch, dinner, or snack. */
  type: MealType;
  /** The day the meal is planned for */
  day: string;
}

export const returnMealType = (type: string): MealType => {
  switch (type){
    case 'lunch':
      return MealType.LUNCH;
    case 'breakfast':
      return MealType.BREAKFAST;
    case 'dinner':
      return MealType.DINNER;
    case 'snack':
      return MealType.SNACK;
    default:
      console.error('Unknown meal type');
      return MealType.UNKNOWN;
  }
};

export enum MealType {
  LUNCH = 'lunch',
  BREAKFAST = 'breakfast',
  DINNER = 'dinner',
  SNACK = 'snack',
  UNKNOWN = 'unknown'
}
