import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DocumentSnapshot } from '@angular/fire/firestore/interfaces';
import { NEW_RECIPE } from 'src/models/recipe';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(
    private afs: AngularFirestore, 
    private authService: AuthService
  ) { }

  get(id: string): Promise<NEW_RECIPE> {
    return new Promise((resolve, reject) => { 
      this.afs.collection('recipes').doc(id).get().toPromise().then((snap) => {
        const snapShot: DocumentSnapshot<any> = snap as DocumentSnapshot<any>;
        resolve(new NEW_RECIPE(snapShot));
      });
    });
  }

  getNew(): NEW_RECIPE {
    return new NEW_RECIPE(
      null,
      null,
      '',
      0,
      [{
        name: 'Recipe Section',
        steps: [{
          text: '',
          ingredients: []
        }]
      }],
      [],
      this.authService.currentid,
      '',
      [],
      'draft',
      'needs_enrichment',
      new Date(),
      new Date()
    );
  }

  add(recipe: NEW_RECIPE ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afs.collection('recipes').add(recipe).then((doc) => {
        resolve(doc.id);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  update(recipe: NEW_RECIPE ): Promise<void> {
    return new Promise((resolve, reject) => {
      const updatedRecipe = {
        ...recipe,
        updated: new Date()
      };
      this.afs.collection('recipes').doc(recipe.id).update(updatedRecipe).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }
  delete() {
    //TODO: [MEAL-189] write delete function
  }
}
