import { RecipeStep } from './recipeStep';
import { Image } from './image';
import { RecipeIngredient } from './recipeIngredient';
import { DocumentSnapshot } from '@angular/fire/firestore/interfaces';
import { Fraction } from "@scottwittrock/recipe";

export class NEW_RECIPE {

  // Follow this spec when possible https://developers.google.com/search/docs/data-types/recipe
  id?: string | null;
  name: string;
  yield: number;
  sections: Section[];
  images: Image[];
  creator_id: string;
  source: string;
  source_files: string[];
  status: 'draft' | 'published' | null;
  review_status: 'needs_enrichment' | 'enriched' | null;
  created: Date;
  updated: Date;

  constructor(
    snap: DocumentSnapshot<any> | null,
    id?: string | null,
    name?: string,
    yield_constructor?: number,
    sections?: Section[],
    images?: Image[],
    creator_id?: string,
    source?: string,
    source_files?: string[],
    status: 'draft' | 'published'| null = null,
    review_status: 'needs_enrichment' | 'enriched' | null = null,
    created?: Date,
    updated?: Date,
  ) {
    this.id = snap ? snap.id : id;
    this.name = snap ? snap.data().name : name;
    this.yield = snap ? snap.data().yield : yield_constructor;
    this.sections = snap ? snap.data().sections : sections;
    this.images = snap ? snap.data().images : images;
    this.creator_id = snap ? snap.data().creator_id : creator_id;
    this.source = snap ? snap.data().source : source;
    this.source_files = snap ? snap.data().source_files : source_files;
    this.status = snap ? snap.data().status : status;
    this.review_status = snap ? snap.data().review_status : review_status;
    this.created = snap ? snap.data().created : created;
    this.updated = snap ? snap.data().updated : updated;
  }

  private _ingredients?: RecipeIngredient[];

  get ingredients(): RecipeIngredient[]{ 
    if(this._ingredients) {
      return this._ingredients;
    } else {
      const ingredients: RecipeIngredient[] = [];
      const ingredientsStrings: string[] = [];
      const newSections = [...this.sections];
      newSections.forEach((section)=> {
        section.steps.forEach((step) => {
          step.ingredients.forEach((ingredient) => {
            const matchedId = ingredientsStrings.indexOf(ingredient.title.toLowerCase());
            if(matchedId >= 0 && ingredients[matchedId].measure === ingredient.measure){
              // Need to handle converting measurements
              if(ingredients[matchedId]) {
                ingredients[matchedId].quantity = Fraction.add(ingredients[matchedId].quantity, ingredient.quantity);
                const newNumber = ingredients[matchedId].numberOfSteps || 0;
                ingredients[matchedId].numberOfSteps = newNumber + 1 ;
              }
            } else {
              ingredientsStrings.push(ingredient.title.toLowerCase());
              ingredients.push({
                ...ingredient,
                numberOfSteps: 1
              });
            }
          })
        })
      })

      this._ingredients = ingredients;
      return ingredients;
    }
  }

}

export class Section {

  name: string;
  steps: HowToStep[];

  constructor(
    name: string,
    steps: HowToStep[]
  ) {
    this.name = name;
    this.steps = steps;
  }

}
export class HowToStep {

  text: string;
  ingredients: RecipeIngredient[]

  constructor(
    text: string,
    ingredients: RecipeIngredient[]
  ) {
    this.text = text
    this.ingredients = ingredients
  }

}

export class NEW_Image {
  path: string;
  originalUrl: string;
  thumbnailUrl: string;
  constructor(
    path: string,
    originalUrl: string,
    thumbnailUrl: string,
  ) {
    this.path = path;
    this.originalUrl = originalUrl;
    this.thumbnailUrl = thumbnailUrl;

  }
}

export type RECIPE_CATEGORY_STRINGS = 'lunch' | 'breakfast' | 'dinner' | 'snack';



export enum RECIPE_CATEGORY {
  LUNCH = 'lunch',
  BREAKFAST = 'breakfast',
  DINNER = 'dinner',
  SNACK = 'snack'

}

interface BaseRecipe {
  /** Main slug for the recipe used to display in the ui. */
  title: string;
  /** Grouping for the recipe */
  category: RECIPE_CATEGORY;
  /**
   * Unique id of all ingredients used in this recipe. Located here to make querying for all ingredients easier. 
   * This should be populated when an ingredients is added or removed from a step.
  */
  ingredients: Array<RecipeIngredient>;
  /**
   * The number of people the ingredient quantities are set to serve. 
   */
  servings: number;
}

/**
 * Recipe type with any dates stored as Timestamps
 */
export interface FirebaseRecipe extends BaseRecipe {
  images: Array<string>
}

export interface OLD_Recipe extends BaseRecipe {
  /** Unique id for recipe. This is mapped from the Snapshot when pulled from firebase. */
  id?: string;
  /** Optional as it should only be populated from the subcollection when needed.  */
  steps?: Array<RecipeStep>;
  images: Array<Image>;
}

/**
 * Convert a recipe utilized on the client to an object which can be saved to Firebase. This will clean up any properties that do not need to be persisted in the db.
 * @param recipe Local recipe you want to save to firebase.
 */
export function mapToFirebaseRecipe(recipe: OLD_Recipe) {
  const tempRecipe = {
    ...recipe,
    images: recipe.images.map((image) => { return image.id })
  }
  delete tempRecipe.id;
  delete tempRecipe.steps;
  return tempRecipe;
}
/**
 * Convert the recipe from the firebase object to a uniform recipe type to use locally.
 * @param recipe The recipe object which you can grab from the firebase snapshot.
 * @param id Id for the recipe. Grab from the snapshot.
 */
export function mapToClientRecipe(recipe: FirebaseRecipe, id: string): OLD_Recipe {
  const images: Array<Image> = recipe.images.map((idMap) => {
    return {
      id: "dafdsaf",
      url: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.simplyrecipes.com%2Frecipes%2Fkale_caesar_salad_with_creamy_parmesan_dressing%2F&psig=AOvVaw0bWfdkz2-eUKKbBzBoHTyD&ust=1578714387513000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJCHnsSP-OYCFQAAAAAdAAAAABAD'
    }
  })

  return {
    ...recipe,
    id,
    images
  }
}