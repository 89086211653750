import { Component, OnInit } from '@angular/core';
import { AuthService } from '../firebase/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../services/navigation/navigation.service';
import { UserService } from '../firebase/user/user.service';
import { User } from 'src/models/user';
import { ConsumerSubscriptionService } from '../services/consumer-subscription/consumer-subscription.service';
import { FirebaseShortLinkService } from '../firebase/short-link/short-link.service';
import { ShortLink } from 'src/models/shortLink';
import { consumerSubscription } from 'src/models/consumerSubscription';

@Component({
  selector: 'app-page-connect',
  templateUrl: './page-connect.component.html',
  styleUrls: ['./page-connect.component.scss']
})
export class PageConnectComponent implements OnInit {

  shortLink: ShortLink;
  authError = '';
  currentView = 'email';

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private userService: UserService, 
    private shortLinkService: FirebaseShortLinkService,
    private consumerSubscriptionService: ConsumerSubscriptionService
  ) { }

  ngOnInit(): void {
    this.navigationService.hide();
    const username = this.route.snapshot.params.id;
    this.shortLinkService.find(username).then((shortLink: ShortLink) => {
      console.log(shortLink);
      this.shortLink = shortLink;
      console.log(this.auth.currentid);
      if (this.auth.currentid) {
        this.checkSubscription(this.auth.currentid)
      } else {
      }
    }).catch((err) => {
      console.log(err);
    });


  }

  checkSubscription(id): Promise<void>{
    return new Promise((resolve, reject) => {
      console.log(this.auth.currentid);
      console.log('Auth user exists');
      this.consumerSubscriptionService.get(this.auth.currentid).then((subs) => {
        console.log('Returned subscriptions');
        console.log(subs);
        subs.forEach((sub: consumerSubscription) => {
          console.log({creator: sub.creator});
          console.log({shortLink: this.shortLink.creator_id})
          if (sub.creator === this.shortLink.creator_id) {
            console.log("subscription matches subscriptions");
            this.navigationService.show();
            this.router.navigate(['/']);
          }
        });
        resolve();
      });
    });
  }


  async emailSubmitted(event: any) {
    const email = event.detail.email
    try {
      const emailExists = await this.auth.checkEmail(email);
      if(emailExists) {
        this.currentView = 'password';
      } else {
        this.currentView = 'createPass';
      }
    } catch (err) {
      console.error(err);
    }
  }
  createUser(event: any) {
    const email = event.detail.email;
    const pass = event.detail.password;
    this.auth.createUser(email, pass).then((id: string)=> {
      this.login(email, pass);
    }).catch((err) => {
      this.authError = err;
    });
  }

  loginSubmitted(event: any) {
    const email = event.detail.email;
    const pass = event.detail.password;
    this.login(email, pass);
  }
  login(email: string, pass: string) {
    this.auth.login(email, pass).then((id) => {
      this.checkSubscription(id).then(() => {
        this.consumerSubscriptionService.add(id, this.shortLink.creator_id).then(() => {
          this.navigationService.show();
          this.router.navigate(['/']);
        }).catch((err) => {
          this.authError = 'There was a problem adding your subscription';
        });
      })

    }).catch((err: string) => {
      this.authError = err;
    })
   
  };

  forgotPassword(event: any) {
    const email = event.detail.email;
    this.auth.sendPasswordResetEmail(email).then(() => {
    }).catch((err: any) => {
      console.error(err);
    })
  }
}
