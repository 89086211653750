import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meal, MealType } from 'src/models/meal';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMealService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService
  ) { }


  add(meal: Meal): Promise<any>{
    const mealToAdd = {
      ...meal
    };
    delete mealToAdd.id;
    return this.afs.collection('users').doc(this.auth.currentid).collection('meals').add(mealToAdd);
  }

  get(id: string): Promise<Meal> {
    return this.afs.collection('users').doc(this.auth.currentid).collection('meals').doc(id).get().pipe(map(e => {
      const fbMeam = e.data();
      const newMeal: Meal = {
        id: e.id,
        created: fbMeam.created.toDate(),
        recipeId: fbMeam.recipeId,
        title: fbMeam.title,
        imageUrl: fbMeam.imageUrl,
        diners: fbMeam.diners,
        type: fbMeam.type,
        day: fbMeam.day,
      }
      return newMeal;
    })).toPromise();
  }

  update(meal: Meal): Promise<void>{
    const newMeal = {
      ...meal
    }
    delete newMeal.id;
    return this.afs.collection('users').doc(this.auth.currentid).collection('meals').doc(meal.id).update(newMeal);
  }

  delete(id: string): Promise<void>{
    return this.afs.collection('users').doc(this.auth.currentid).collection('meals').doc(id).delete();
  }
}
