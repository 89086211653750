export const environment = {
  production: true,
  functions: 'xxxxxx',
  useEmulators: false,
  storage: {
    bucket: 'meal-planner-d5fc4-production'
  },
  firebase: {
    apiKey: "AIzaSyDX_IvZF0T31UXT4RcKXhNi_7FThMvGlKo",
    authDomain: "meal-planner-d5fc4.firebaseapp.com",
    databaseURL: "https://meal-planner-d5fc4.firebaseio.com",
    projectId: "meal-planner-d5fc4",
    storageBucket: "meal-planner-d5fc4.appspot.com",
    messagingSenderId: "735792104989",
    //Consumer app id
    appId: "1:735792104989:web:70545c801f042797fa68f4"
  }
};
