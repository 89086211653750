import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meal } from 'src/models/meal';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMealsService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService

  ) { 

  }

  query(dateString: string): Observable<Meal[]>{
    return this.afs.collection('users')
      .doc(this.auth.currentid)
      .collection('meals', ref => ref.where('day', '==', dateString))
      .valueChanges({idField: 'id'})
      .pipe(map(ev => {
        return ev.map((item) => {
          return {
            id: item.id,
            created: item.created.toDate(),
            recipeId: item.recipeId,
            title: item.title,
            imageUrl: item.imageUrl,
            diners: item.diners,
            type: item.type,
            day: item.day
          };
        });
      }))

  }


}
