<div class="content">
  <div class="date">{{todayDateString}}</div>
  <div class="welcome">Hello<span *ngIf="name" class="welcome">{{name}}</span>, ready to cook?</div>
</div>

<span *ngIf="planDay?.breakfast.length > 0">
<h1>Breakfast</h1>
  <app-meal-list-item *ngFor="let meal of planDay?.breakfast" [meal]="meal" (click)="goToRecipe(meal)"></app-meal-list-item>
</span>
<span *ngIf="planDay?.lunch.length > 0">
<h1>Lunch</h1>
  <app-meal-list-item *ngFor="let meal of planDay?.lunch" [meal]="meal" (click)="goToRecipe(meal)"></app-meal-list-item>
</span>

<span *ngIf="planDay?.dinner.length > 0">
<h1>Dinner</h1>
  <app-meal-list-item *ngFor="let meal of planDay?.dinner" [meal]="meal" (click)="goToRecipe(meal)"></app-meal-list-item>
</span>

<span *ngIf="planDay?.snack.length > 0">
<h1>Snack</h1>
  <app-meal-list-item *ngFor="let meal of planDay?.snack" [meal]="meal" (click)="goToRecipe(meal)"></app-meal-list-item>
</span>

<g-empty-state 
  *ngIf="planDay?.breakfast.length === 0 
    && planDay?.lunch.length === 0
    && planDay?.dinner.length === 0
    && planDay?.snack.length === 0"
  [headline]="'No Planned Meals'"
  [body]="'You don\'t have any meals planned for today'"
  [button]="'Add Meals'"
  (buttonClick)="goToPlan()"
  [src]="'assets/consumer/empty_cook.svg'"
>
</g-empty-state>

<!-- TODO add cook arrow here -->
<g-img src>

</g-img>


