import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { consoleSandbox } from '@sentry/utils';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
@Sentry.TraceClassDecorator()
export class NavigationComponent implements OnInit {
  buttons = [{
    id: "recipes",
    name: "Recipes",
    icon: "/assets/consumer/icon_creator.svg",
    iconActive: "/assets/consumer/icon_creator_active.svg"
  }, {
    id: "plan",
    name: "Plan",
    icon: "/assets/consumer/icon_plan.svg",
    iconActive: "/assets/consumer/icon_plan_active.svg"
  }, {
    id: "shop",
    name: "Shop",
    icon: "/assets/consumer/icon_shop.svg",
    iconActive: "/assets/consumer/icon_shop_active.svg"
  }, {
    id: "cook",
    name: "Cook",
    icon: "/assets/consumer/icon_cook.svg",
    iconActive: "/assets/consumer/icon_cook_active.svg"
  }, {
    id: "profile",
    name: "Profile",
    icon: "/assets/consumer/icon_profile.svg",
    iconActive: "/assets/consumer/icon_profile_active.svg"
  }];

  active = 0;
  showNav;

  checkInterval;

  constructor(
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private router: Router
  ) {
      this.navigation.watch.subscribe((result) => {
        this.showNav = result;
      });
    // TODO: Fix this as it doesn't always work
    this.checkInterval = setInterval(() => {
      const url = this.router.url;
      if (url && url !== '/') {
        clearInterval(this.checkInterval);
        this.setActive(url);
      }
    }, 1);
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActive(event.url);
      }
    })
  }

  setActive(url) {
    switch (url) {
      case '/recipes':
        this.active = 0;
        break;
      case '/plan':
        this.active = 1;
        break;
      case '/shop':
        this.active = 2;
        break;
      case '/cook':
        this.active = 3;
        break;
      case '/profile':
        this.active = 4;
        break;
      default:
        break;
    }
  }

  navigate(e): void {
    if(e.detail.id === 'logo'){
      this.router.navigate(['/']);
    } else {
      this.router.navigate([e.detail.id]);
    }

  }

}
