import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';

import * as Sentry from "@sentry/angular";
import { PlanDayService } from '../services/plan-day/plan-day.service';
import { PlanDay } from 'src/models/planDay';

import { DateTime } from 'luxon';
import { AuthService } from '../firebase/auth/auth.service';
import { UserService } from '../firebase/user/user.service';
import { Router } from '@angular/router';
import { Meal } from 'src/models/meal';

@Component({
  selector: 'app-page-cook',
  templateUrl: './page-cook.component.html',
  styleUrls: ['./page-cook.component.scss']
})
@Sentry.TraceClassDecorator()
export class PageCookComponent implements OnInit {

  planDay: PlanDay = {
    breakfast: [],
    lunch: [],
    dinner: [],
    snack: []
  };
  todayDateString = DateTime.now().toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' });
  name: string;

  constructor(
    private navigationService: NavigationService,
    private planDayService: PlanDayService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navigationService.show();
    this.planDayService.setToToday();
    this.planDayService.watch().subscribe((plan: PlanDay) => {
      this.planDay = plan;
    });
    this.userService.currentUser().then((user) => {
      let namestring = '';
      if (user.firstName) {
        namestring = namestring + ' ' + user.firstName;
      }
      this.name = namestring;
    });
  }

  goToPlan() {
    this.router.navigate(['plan']);

  }

  goToRecipe(meal: Meal): void{
    this.router.navigate(['cook', meal.recipeId],{ queryParams: { dinners: meal.diners } });
  }

}
