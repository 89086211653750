<div class="input-container">
    <g-input
      [placeholder]="placeholder"
      (changed)="searchChanged($event)"
      (pressedReturn)="handleSubmit()"
    ></g-input>
    <div 
      *ngIf="searchTerm && submitText"
      class="add-button"
      (click)="handleSubmit()"
    >Add</div>
  </div>