<div class="container">
  <g-img *ngIf="meal?.imageUrl" [src]="meal?.imageUrl"></g-img>
  <div class="text-container">
    <span class="title">{{meal?.title}}</span>
    <span>
      <span class="subtext">
      </span>
    </span>
    <span class="subtext">Dinners: {{meal?.diners}}</span>
  </div>
</div>