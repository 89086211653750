import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GroceryItem } from 'src/models/groceryItem';
import { FirebaseGroceryListService } from 'src/app/firebase/grocery-list/grocery-list.service';
import { FirebaseIngredientsService } from 'src/app/firebase/ingredients/firebase-ingredients.service';
import { GROCERY_LOCATION } from 'src/models/groceryLocation';



@Injectable({
  providedIn: 'root'
})
export class GroceryListService {

  //TODO Fix this
  groceryList: GroceryItem[] = [];

  //TODO Fix this
  watch: BehaviorSubject<GroceryItem[]> = new BehaviorSubject([]);

  constructor(
    private fbGroceryListService: FirebaseGroceryListService,
    private ingredientsService: FirebaseIngredientsService
  ) {
    //TODO Fix this
    // this.fbGroceryListService.watch().subscribe((serverItems: ShoppingListUpdateModel) =>{
    //   const localItems = JSON.parse(localStorage.getItem('grocery_list')) || {};
    //   console.log(localItems);
    //   console.log(serverItems);
    //   if(localItems.updated && serverItems.updated) {
    //     localItems.updated = new Date(localItems.updated);
    //     if (serverItems.updated.getTime() > localItems.updated.getTime()) {
    //       console.log('Server items newer');
    //       this.update(serverItems.items);
    //     } else if (serverItems.updated.getTime() < localItems.updated.getTime()){
    //       console.log('Local items newer');
    //       this.update(localItems.items);
    //     } else {
    //       console.log('Items are the same');
    //     }
    //   } else if(!localItems.updated && serverItems.updated) {
    //     console.log('localItems doesnt exist');
    //     this.update(serverItems.items)
    //   } else if(!serverItems.updated && localItems.updated) {
    //     console.log('Serveritems doesnt exist');
    //     this.update(localItems.items)
    //   } else {
    //     console.log('Neither items exist. I.E. its a new user');
    //     this.update([]);
    //   }
    // });
  }
  query(): Observable<GroceryItem[]> {
    return this.fbGroceryListService.query();
  }
  add(item: GroceryItem): Promise<void>{
    return new Promise((resolve, reject) => {
      this.ingredientsService.getCategory(item.title).then((location: GROCERY_LOCATION) => {
        console.log(location);
        if(location) {
          item.aisle = location;
          this.fbGroceryListService.add(item).then(() =>{
            resolve();
          }).catch((err) => {
            reject()
          });
        }else {
          this.fbGroceryListService.add(item).then(() =>{
            resolve()
          }).catch((err) => {
            reject()
          });
        }
      })
    });
  }

  update(item: GroceryItem): Promise<void>{
    return this.fbGroceryListService.update(item);
  }

  delete(id: string): Promise<void> {
    return this.fbGroceryListService.delete(id);
  }

  bulkAdd(items: GroceryItem[]): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const promises = [];
      items.forEach((item) => {
        promises.push(this.ingredientsService.getCategory(item.title));
      })
      const categories = await Promise.all(promises);
      const itemsCategory = items.map((item:GroceryItem, i) => {
        return {
          ...item,
          aisle: categories[i]
        }
      });
      
      return this.fbGroceryListService.bulkAdd(itemsCategory)
    });
  }

  //TODO Fix this
  // bulkAdd(items: GroceryItem[]): GroceryItem[] {
  //   items.forEach((item)=> {
  //     this.groceryList.push(item);
  //   });
  //   this.watch.next([...this.groceryList]);

  //   this.update([...this.groceryList]);
  //   return [...this.groceryList];
  // }
  //TODO Fix this

  //TODO Fix this
  // update(list: GroceryItem[]) {
  //   const newList = {
  //     updated: new Date(),
  //     items: [...list]
  //   };

  //   this.watch.next([...list]);
  //   localStorage.setItem('grocery_list', JSON.stringify(newList));
  //   // this.fbGroceryListService.save(newList);

  // }
 
  //TODO Fix this
  // delete(){

  // }



}
