import { Component, OnInit } from '@angular/core';
import { NEW_RECIPE } from 'src/models/recipe';
import { RecipesService } from '../services/recipes/recipes.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { UserService } from '../firebase/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-recipes',
  templateUrl: './page-recipes.component.html',
  styleUrls: ['./page-recipes.component.scss']
})
export class PageRecipesComponent implements OnInit {
  recipes: NEW_RECIPE[] = [];

  constructor(
    private recipesService: RecipesService,
    private navigationService: NavigationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navigationService.show();
    const noUserCreator = localStorage.getItem('no_user_creator_link');
    if (noUserCreator) {
      this.router.navigate([`creator/${noUserCreator}`]);
    }
    this.recipes = JSON.parse(localStorage.getItem('recipes'));
    this.recipesService.query({

    }).then((recipes: NEW_RECIPE[]) => {
      this.recipes = recipes;
    });


  }

  navToRecipe(id: string): void {
    this.router.navigate([`recipe/${id}`]);
  }

  goToSearch(){
    this.router.navigate([`search-recipes`]);
  }
}
