import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ShortLink } from 'src/models/shortLink';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseShortLinkService {

  private watch$: BehaviorSubject<ShortLink>;

  constructor(
    private afs: AngularFirestore
  ) {
    this.watch$ = new BehaviorSubject({
      creator_id: '',
      username: '',
      headline: '',
      subtitle: '',
      image_url: '',
    });
  }


  watch(id: string): BehaviorSubject<ShortLink> {
    console.log(id);
    this.afs.collection('short_links').doc(id).valueChanges().subscribe((item) => {
      console.log(item);
      const link: ShortLink = item as ShortLink;
      this.watch$.next(link);
    });
    return this.watch$;
  }

  find(userNameOrId: string): Promise<ShortLink> {
    return new Promise((resolve, reject) => {
      this.afs.collection('short_links').doc(userNameOrId).get().toPromise().then((snap) => {
        if (snap.exists) {
          const shortLink: ShortLink = snap.data() as ShortLink;
          resolve(shortLink);
        } else {
          this.afs.collection('short_links', ref => ref.where('username', '==', userNameOrId)).get().toPromise().then((snaps) => {
            const link = snaps.docs[0].data() as ShortLink;
            resolve(link)
          })
        }
      }).catch((err) => {
        reject(err);
      })
    })
  }


  get(id: string): Promise<ShortLink> {
    return new Promise((resolve, reject) => {
      this.afs.collection('short_links').doc(id).get().toPromise().then((snap) => {
        if (snap.exists) {
          const shortLink: ShortLink = snap.data() as ShortLink;
          resolve(shortLink);
        } else {
          reject();
        }
      }).catch((err) => {
        reject(err);
      })
    })
  }

  update(shortLink: ShortLink): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afs.collection('short_links').doc(shortLink.creator_id).set(shortLink).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      })
    })
  }

}
