<g-nav-app-header
[heading]="'Details'"
[back]="{
  id: 'search', 
  src: 'assets/consumer/icon_back.svg'
}"
(backClicked)="goback()"
>
  
</g-nav-app-header>


<div style="margin: 0px 16px 100px 16px;">
  <g-img *ngIf="recipe?.images?.length > 0" style="width: calc(100% + 32px); left: -16px; position: relative;" [src]="recipe?.images[0]?.url">
  
  </g-img>
  
  <div class="button-container" (click)="addToShoppingList()">
    <div class="add-button">
      Add to Shopping List
    </div>
<!--   
    <div class="add-button" style="border-left: 1px solid #E0E0E0;">
      Add to Meal Plan
    </div> -->
  </div>
  
  <div class="content">
    <span class="title">
      {{recipe?.name}}
    </span>
    <app-author-tag
      [src]="shortLink?.image_url"
      [title]="shortLink?.headline"
      [subtitle]="shortLink?.subtitle"
    >
    </app-author-tag>

    <div class="fact-container">
      <app-fact-callout
        [number]="recipe?.ingredients?.length"
        [label]="'Ingredients'"
      ></app-fact-callout>      
      <!-- <app-fact-callout
        [number]="recipe?.yield"
        [label]="'Servings'"
      ></app-fact-callout>     -->
      <div class="scale-container">
        <g-img (click)="removeDinner()" class="scale-buttons" [src]="'assets/consumer/icon_subtract.svg'">

        </g-img>
        <app-fact-callout
          [number]="dinners"
          [label]="'Dinners'"
        ></app-fact-callout>  
        
        <g-img (click)="addDinner()" class="scale-buttons" [src]="'assets/consumer/icon_add_filled.svg'">

        </g-img>  
      </div>
      <div>
        <g-button (gClick)="startCooking()">Start Cooking</g-button>
      </div>
    </div>
    <div class="directions-container">
      <div class="ingredients-contianer">
        <div class="heading">
            Ingredients
        </div>
        <div style="margin-bottom: 20px;">
          <app-recipe-ingredient
            *ngFor="let ingredient of recipe?.ingredients"
            [ingredient]="ingredient"
            [scale]="scale"
            >
          </app-recipe-ingredient>
        </div>
      </div>
      <div>
        <div class="heading">
          Instructions
        </div>
        <span *ngFor="let section of recipe?.sections">
          <div *ngFor="let step of section.steps; let index = index">
            <div  style="font-size: 17px; font-weight: 700;">Step {{index + 1}}</div>
            <p>{{step.text}}</p>
          </div>
        </span>
      </div>
    </div>
  
  
  </div>
</div>