
export const returnGroceryLocation = (item: string): GROCERY_LOCATION => {
  switch(item){
    case 'fresh produce': 
      return GROCERY_LOCATION.PRODUCE;
    case 'deli': 
      return GROCERY_LOCATION.DELI;
    case 'bakery': 
      return GROCERY_LOCATION.BAKERY;
    case 'spices': 
      return GROCERY_LOCATION.SPICES;
    case 'baking': 
      return GROCERY_LOCATION.BAKERY;
    case 'condiments': 
      return GROCERY_LOCATION.CONDIMENTS;
    case 'dairy': 
      return GROCERY_LOCATION.DAIRY;
    case 'frozen foods': 
      return GROCERY_LOCATION.FROZEN;
    case 'snacks': 
      return GROCERY_LOCATION.SNACKS;
    case 'alcohol/spirits': 
      return GROCERY_LOCATION.ALCOHOL;
    case 'drinks': 
      return GROCERY_LOCATION.DRINKS;
    case 'canned/jarred': 
      return GROCERY_LOCATION.CANNED;
    case 'pasta': 
      return GROCERY_LOCATION.PASTA;
    case 'refrigerated': 
      return GROCERY_LOCATION.REFRIGERATED;
    case 'other': 
      return GROCERY_LOCATION.OTHER
    default: 
      return GROCERY_LOCATION.OTHER
  }
}


export enum GROCERY_LOCATION { 
  PRODUCE = 'fresh produce',
  DELI = 'deli', 
  BAKERY = 'bakery', 
  SPICES = 'spices', 
  BAKING = 'baking', 
  CONDIMENTS = 'condiments', 
  DAIRY = 'dairy', 
  FROZEN = 'frozen foods', 
  SNACKS = 'snacks', 
  ALCOHOL = 'alcohol/spirits',
  DRINKS = 'drinks',
  CANNED = 'canned/jarred', 
  PASTA = 'pasta',
  REFRIGERATED = 'refrigerated',
  OTHER = 'other'
}