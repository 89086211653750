import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GroceryItem } from 'src/models/groceryItem';
import { GROCERY_LOCATION } from 'src/models/groceryLocation';



@Injectable({
  providedIn: 'root'
})
export class FirebaseGroceryListService {

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore
  ) { }

  query(): any {
    return this.afs.collection('users')
      .doc(this.authService.currentid)
      .collection(
        'grocery_items',
        ref => ref.where('deleted', '==', false)
          .orderBy('title')
      ).valueChanges({ idField: 'id' })
      .pipe(map(ev => {
        return ev.map(item => {
          const newItem: GroceryItem = {
            id: item.id,
            original: item.original,
            title: item.title,
            created: item.created,
            purchased: item.purchased,
            purchased_date: item.purchased_date,
            quantity: item.quantity,
            measure: item.measure,
            aisle: item.aisle,
            /** List of ids which this ingredient item belongs to. */
            recipes: item.recipes,
            /** List of recipe titles which this ingredient item belongs to. */
            recipe_titles: item.recipe_titles,
            review_status: item.review_status || [],
            deleted: item.deleted,
          }
          return newItem;
        }).sort((a: GroceryItem, b: GroceryItem) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
      }));

  }

  add(item: GroceryItem): Promise<void> {
    return new Promise((resolve, reject) => {
      const newItem = { ...item };
      delete newItem.id;
      this.afs.collection('users')
        .doc(this.authService.currentid)
        .collection('grocery_items')
        .add(newItem).then((result) => {
          resolve();
        }).catch((err) => {
          reject(err);
        })
    })
  }
  update(item: GroceryItem): Promise<void> {
    return new Promise((resolve, reject) => {
      const newItem = { ...item };
      delete newItem.id;
      this.afs.collection('users')
        .doc(this.authService.currentid)
        .collection('grocery_items')
        .doc(item.id).update(newItem).then((result) => {
          resolve();
        }).catch((err) => {
          reject(err);
        })
    })
  }

  delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afs.collection('users')
        .doc(this.authService.currentid)
        .collection('grocery_items')
        .doc(id).update({
          deleted: true
        }).then((result) => {
          resolve();
        }).catch((err) => {
          reject(err);
        })
    })
  }

  bulkAdd(items: GroceryItem[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const batch = this.afs.firestore.batch();
      items.forEach((item) => {
        const newItem = { ...item };
        delete newItem.id;
        const ref = this.afs.firestore
          .collection('users')
          .doc(this.authService.currentid)
          .collection('grocery_items').doc()
        batch.set(ref, newItem)
      })
      batch.commit().then(() => {
        resolve()
      }).catch((err) => {
        reject(err);
      })

    })

  }

  //TODO Fix this
  // watch(): Observable<ShoppingListUpdateModel> {
  //   return this.afs.collection('users')
  //     .doc(this.authService.currentid).collection('grocery_lists').doc('default').valueChanges().pipe(map(items => {
  //       console.log(items);
  //       if(items) {
  //         return {
  //           items: [...items.items],
  //           updated: items.updated.toDate()
  //         };
  //       } else {
  //         return {
  //           items: [],
  //           updated: null
  //         }
  //       }
  //   }));
  // }
  //TODO Fix this
  // get(): Promise<ShoppingListUpdateModel> {
  //   return this.afs.collection('users')
  //     .doc(this.authService.currentid)
  //     .collection('grocery_lists')
  //     .doc('default')
  //     .get()
  //     .toPromise()
  //     .then((result) => {
  //       const resultEnd: ShoppingListUpdateModel = {
  //         items: [...result.data().items],
  //         updated: result.data().updated.toDate()
  //       };
  //       return resultEnd;
  //     });
  // }




  //TODO Fix this
  // save(list: ShoppingListUpdateModel): void{
  //   this.afs.collection('users').doc(this.authService.currentid).collection('grocery_lists').doc('default').set(list);
  // }

}
