export class User {
  id?: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  created: Date;
  updated: Date;
  organization: string | null;
  profile_url: string | null;
  adminPermissions: {
    dashboard_admin?: boolean | null
    dashboard_creator?: boolean | null
    dashboard_creator_active?: boolean | null
  };
  stripe_customer_id: string | null;

  constructor (
    id: string,
    firstName: string | null,
    lastName: string | null,
    email: string,
    created: Date,
    updated: Date, 
    organization: string | null,
    profile_url: string | null,
    adminPermissions: {
      dashboard_admin?: boolean | null
      dashboard_creator?: boolean | null
      dashboard_creator_active?: boolean | null
    },
    stripe_customer_id: string | null
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.created = created;
    this.updated = updated;
    this.organization = organization;
    this.profile_url = profile_url;
    this.adminPermissions = adminPermissions;
    this.stripe_customer_id = stripe_customer_id
  }
}