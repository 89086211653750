import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RecipeIngredient } from 'src/models/recipeIngredient';
import { Scale } from "@scottwittrock/recipe";
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-recipe-ingredient',
  templateUrl: './recipe-ingredient.component.html',
  styleUrls: ['./recipe-ingredient.component.scss']
})
@Sentry.TraceClassDecorator()
export class RecipeIngredientComponent implements OnInit, OnChanges {

  display: string = '';

  @Input() ingredient: RecipeIngredient;
  @Input() scale: number;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(): void{
    this.display = this.setDisplay();
  }

  setDisplay(): string {
    let newTitle = '';
    if (this.ingredient.quantity){
      if (this.scale !== 1) {
        newTitle = Scale(this.ingredient.quantity, this.ingredient.measure, this.scale).quantity + ' ';
      } else {
        newTitle = this.ingredient.quantity + ' ';
      }
    }
    if(this.ingredient.measure){
      newTitle = newTitle + this.ingredient.measure + ' ';
    }
    if(this.ingredient.title){
      newTitle = newTitle + this.ingredient.title + ' ';
    }
    if(this.ingredient.prep){
      newTitle = newTitle + this.ingredient.prep + ' ';
    }
    return newTitle;
  }


}
