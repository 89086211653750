import { Component, OnInit, Input } from '@angular/core';
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-author-tag',
  templateUrl: './author-tag.component.html',
  styleUrls: ['./author-tag.component.scss']
})
@Sentry.TraceClassDecorator()
export class AuthorTagComponent implements OnInit {

  @Input()src: string;
  @Input()title: string;
  @Input()subtitle: string;

  constructor() { }

  ngOnInit(): void {
    
  }

}
