import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationService } from '../services/navigation/navigation.service';
import { MealService } from '../services/meal/meal.service';
import { ActivatedRoute } from '@angular/router';
import { Meal } from 'src/models/meal';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-page-meal-detail',
  templateUrl: './page-meal-detail.component.html',
  styleUrls: ['./page-meal-detail.component.scss']
})
export class PageMealDetailComponent implements OnInit {

  meal: Meal;
  displayTime;
  confirmRemovalPrompt;
  removing;

  constructor(
    private location: Location,
    private navigation: NavigationService, 
    private mealService: MealService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.navigation.hide();
    const id = this.route.snapshot.params.id;
    this.mealService.get(id).then((meal: Meal) => {
      this.displayTime = DateTime.fromISO(meal.day).toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' });
      this.meal = meal;
    });
  }

  goBack() {
    this.location.back();
    this.navigation.show();
  }

  addDinner(){
    const newMeal: Meal = {
      ...this.meal,
      diners: this.meal.diners + 1
    };
    this.meal.diners++;
    this.mealService.update(newMeal);
  }

  removeDinner(){
    if (this.meal.diners > 1){
      const newMeal: Meal = {
        ...this.meal,
        diners: this.meal.diners - 1
      };
      this.meal.diners--;
      this.mealService.update(newMeal);
    }

  }

  promptRemove(): void{
    this.confirmRemovalPrompt = true;
    setTimeout(() => {
      this.confirmRemovalPrompt = false;
    }, 2000);
  }

  confirmRemoval(): void{
    this.removing = true;
    this.mealService.delete(this.meal.id).then(() => {
      this.goBack();
      this.removing = false;
    }).catch(() => {
      this.removing = false;
    });
  }

}
