import { Component, OnInit } from '@angular/core';
import { NEW_RECIPE } from 'src/models/recipe';
import { NavigationService } from '../services/navigation/navigation.service';
import { MealService } from '../services/meal/meal.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RecipesService } from '../services/recipes/recipes.service';

@Component({
  selector: 'app-page-search-recipes',
  templateUrl: './page-search-recipes.component.html',
  styleUrls: ['./page-search-recipes.component.scss']
})
export class PageSearchRecipesComponent implements OnInit {

  allRecipes: NEW_RECIPE[] = [];
  filteredRecipes: NEW_RECIPE[] = [];

  constructor(
    private recipesService: RecipesService,
    private router: Router,
    private navigation: NavigationService,
    private location: Location,
    private mealService: MealService
  ) { }

  ngOnInit(): void {
    this.navigation.hide();
    this.recipesService.query({}).then((recipes) => {
      this.allRecipes = [...recipes];
      this.filteredRecipes = [...recipes];
    });
  }

  debouncingTimeout;
  searching = false;
  firstLoad = true;


  searchChanged(termInput) {
    const lowercaseTerm = termInput.toLowerCase();
    const terms = lowercaseTerm.split(' ');

    if (this.debouncingTimeout || this.firstLoad) {
      this.firstLoad = false;
      this.searching = true;
      clearTimeout(this.debouncingTimeout);
      this.debouncingTimeout = setTimeout(() => {
        this.searching = false;
        const filteredRecipes = this.allRecipes.filter((recipe) => {
          let include = false;
          terms.forEach((term: string)=> {
            const name = recipe.name.toLowerCase();
            if (name.includes(term)) {
              include = true;
            }
            recipe.ingredients.map(i => i.original.toLowerCase()).forEach((ingredient)=> {
              if (ingredient.includes(term)){
                include = true;
              }
            });
          })
          return include;
        });
        this.filteredRecipes = filteredRecipes;
      }, 500);
    }


  }
  submit(e){
    console.log(e);
  }

  handleButtonClick() {
    this.filteredRecipes = this.allRecipes;
  }

  goToRecipe(recipe: NEW_RECIPE){
    this.router.navigate(['recipe', recipe.id]);
  }

  goBack(): void{
    this.navigation.show();
    this.location.back();
  }


}
