import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  watch: BehaviorSubject<boolean> = new BehaviorSubject(true);
  history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  back(defaultRoute?: string): void {
    console.log(defaultRoute)
    const route = defaultRoute || '/';
    console.log(route);
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(route);
    }
  }

  show(): void{
    this.watch.next(true);

  }

  hide(): void{
    var w = window.innerWidth;
    if (w <= 768) {
      this.watch.next(false);
    }
  }



}
