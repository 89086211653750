import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NEW_RECIPE } from 'src/models/recipe';
import { RecipeService } from '../firebase/recipe/recipe.service';

@Component({
  selector: 'app-page-cook-recipe',
  templateUrl: './page-cook-recipe.component.html',
  styleUrls: ['./page-cook-recipe.component.scss']
})
export class PageCookRecipeComponent implements OnInit {

  recipe: NEW_RECIPE;
  dinners;
  scale;

  currentStep = 0;
  totalSteps = 0;

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    const key = event.keyCode;
    if(key === 39) {
      this.goForward();
    }
    if(key === 37) {
      this.goPrevious();
    }
  }


  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private recipeService: RecipeService,
  ) { }

  ngOnInit(): void {
    this.navigationService.hide();
    const id = this.route.snapshot.params.id;
    this.recipeService.get(id).then((recipe) => {
      this.recipe = recipe;
      this.dinners = this.route.snapshot.queryParams.dinners || recipe.yield;
      this.scale = this.dinners / this.recipe.yield;
      this.totalSteps = 0;
      this.recipe.sections.forEach((section) => {
        section.steps.forEach(step => {
          this.totalSteps++;
        });
      });
    });
  }

  ngOnDestroy(){

  }

  goBack(){
    this.navigationService.back(`/recipe/${this.recipe.id}`);
  }

  goForward() {
    if (this.currentStep <= this.totalSteps) {
      this.currentStep = this.currentStep + 1;
      window.scroll(0,0)
    }
  }

  goPrevious(){
    if (this.currentStep !== 0 ) {
      this.currentStep = this.currentStep - 1;
      window.scroll(0,0)
    }
  }


}
