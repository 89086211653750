import { Component, OnInit, Input } from '@angular/core';
import { RecipeIngredient } from 'src/models/recipeIngredient';

@Component({
  selector: 'app-cook-ingredients',
  templateUrl: './cook-ingredients.component.html',
  styleUrls: ['./cook-ingredients.component.scss']
})
export class CookIngredientsComponent implements OnInit {

  @Input() ingredients: RecipeIngredient[];
  @Input() scale: number;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      
      console.log(this.ingredients);
    }, 500)
  }

}
