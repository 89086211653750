import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GROCERY_LOCATION } from 'src/models/groceryLocation';
import { Ingredient } from 'src/models/ingredient';

@Injectable({
  providedIn: 'root'
})
export class FirebaseIngredientsService {

  constructor(
    private afs: AngularFirestore
  ) { }

  getCategory(s: string): Promise<GROCERY_LOCATION> {
    return new Promise((resolve, reject) => {
      const lower = s.toLowerCase();
      this.afs.collection('ingredients', ref => ref.where('title', '==', lower)).get().toPromise().then((snaps) => {
        console.log(snaps);
        if(snaps.empty){
          resolve(GROCERY_LOCATION.OTHER);
        } else {
          console.log(snaps.docs[0]);
          const ingredient: Ingredient = snaps.docs[0].data() as Ingredient;
          const location: GROCERY_LOCATION = ingredient.groceryLocation;
          resolve(location)
        }
      });
    });

  }

}
