<g-nav-app-header
[heading]="'Recipes'"
[action]="{
  id: 'search', 
  src: 'assets/consumer/icon_search.svg'
}"
(actionClicked)="goToSearch()"
>
  
</g-nav-app-header>
<div style="margin: 0px 0 100px 0px;">
  <span *ngIf="recipes?.length > 0" class="recipe-container">
    <app-recipe-list-item 
      *ngFor="let recipe of recipes"
      [recipe]="recipe"
      (click)="navToRecipe(recipe?.id)"
    ></app-recipe-list-item>
  </span>
  
  <g-empty-state 
    *ngIf="recipes?.length === 0" 
    [headline]="'No Recipes'"
    [body]="'You aren\'t subscribed to any creators currently.'"
    [src]="'assets/consumer/empty_basket.svg'"
  >
  </g-empty-state>
</div>