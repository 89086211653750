import { Component } from '@angular/core';
import { NavigationService } from './services/navigation/navigation.service';
import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { isThenable } from '@sentry/utils';
import * as Sentry from "@sentry/angular";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Sentry.TraceClassDecorator()
export class AppComponent {

  showNav = false;

  constructor(
    private navigation: NavigationService,
  ){
    this.navigation.watch.subscribe((result) => {
      this.showNav = result;
    });
    this.initializeApp();
  }

  initializeApp(): void {
    new Promise(async (resolve, reject) => {
      // this.platform.ready().then(() => {
        if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('StatusBar')) {
          await StatusBar.setStyle({ style: StatusBarStyle.Light });
          await StatusBar.show();
        }
        resolve();
        // this.splashScreen.hide();
      // });
    }).then(() => {
      
    })
  }

}
