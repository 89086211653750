import { Component, OnInit, Input } from '@angular/core';
import { HowToStep } from 'src/models/recipe';

@Component({
  selector: 'app-cook-step',
  templateUrl: './cook-step.component.html',
  styleUrls: ['./cook-step.component.scss']
})
export class CookStepComponent implements OnInit {

  @Input() step: HowToStep;
  @Input() scale = 1;


  constructor() { }

  ngOnInit(): void {


  }

}
